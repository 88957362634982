export const DOCUMENT_TYPES = {
  CNTM: 'CNTM', // Contract termination
  HDRQ: 'HDRQ', // Holiday request
  JBAL: 'JBAL', // Job application
  LETR: 'LETR', // Cover letter
  RSME: 'RSME', // Resume
} as const;

export type DocumentTypeKeys = keyof typeof DOCUMENT_TYPES;
export type DocumentTypeValues = DocumentTypeKeys;

// Add migration after changing version
export const DOCUMENT_CURRENT_VERSION = 1;

export type DocumentTypeLowerCaseKeys = Lowercase<DocumentTypeKeys>;

export type DocumentTypeLowerCaseValues = DocumentTypeLowerCaseKeys;

export const DOCUMENT_TYPES_LOWERCASE: Record<DocumentTypeLowerCaseKeys, DocumentTypeLowerCaseValues> = {
  cntm: 'cntm',
  hdrq: 'hdrq',
  jbal: 'jbal',
  letr: 'letr',
  rsme: 'rsme',
};

export const DOCUMENT_TYPES_FROM_URL_PARAM = {
  [DOCUMENT_TYPES_LOWERCASE.cntm]: DOCUMENT_TYPES.CNTM,
  [DOCUMENT_TYPES_LOWERCASE.hdrq]: DOCUMENT_TYPES.HDRQ,
  [DOCUMENT_TYPES_LOWERCASE.jbal]: DOCUMENT_TYPES.JBAL,
  [DOCUMENT_TYPES_LOWERCASE.letr]: DOCUMENT_TYPES.LETR,
  [DOCUMENT_TYPES_LOWERCASE.rsme]: DOCUMENT_TYPES.RSME,
};

export const DOCUMENT_UNITS = {
  cm: 'cm',
  in: 'in',
  pt: 'pt',
};

export const DOCUMENT_FILE_FORMATS = {
  PDF: 'PDF',
  TXT: 'TXT',
} as const;

export type DocumentFileFormat = keyof typeof DOCUMENT_FILE_FORMATS;

export const DOCUMENT_PREFERENCES_TYPES = {
  additionalData: 'ADDATA',
  documentScore: 'DCSCR',
  documentSettings: 'DCSTY',
  experienceClusterId: 'EXPCLR',
  jobType: 'DCJT',
} as const;

export type DocumentPreferencesTypesValues = ValueOf<typeof DOCUMENT_PREFERENCES_TYPES>;

export const DOCUMENT_NAME_MAX_LENGTH = 100;
