// eslint-disable-next-line workspaces/require-dependency
import type { AppInterviewcheckEmbedInitParamsObj } from '@boldpl/app-interviewcheck-ai-embed/src/types/appInit';

// TODO ELO
// TODO ALO
// TODO split ConfigPassed for ICE and FTE

// AppFeedbacktoolEmbedInitParamsObj :(
interface ConfigPassed
  extends Omit<AppInterviewcheckEmbedInitParamsObj, 'appSelector' | 'initLoaderSelector' | 'i18n'> {
  feedbackGraphql?: string;
}

// TO BE USED IN entry.ts and config/index.ts files
export const configPassed = {} as ConfigPassed;
