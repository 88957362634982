import 'core-js/modules/es.string.replace-all';
import 'core-js/modules/web.structured-clone';
import 'core-js/stable/structured-clone';
import 'core-js/stable/string/replace-all';

import type { ConfigSiteLoaded } from '@boldpl/common/config/site/types';

import { configPassed } from '@boldpl/common/config/configPassed';
import { configSiteLoaded } from '@boldpl/common/config/site/configSiteLoaded';

import { siteIdInterviewcheckEmbedValues } from '@boldpl/common/constants/siteId';

import { getConfigVariant } from './getConfigVariant';

window.ngb = window.ngb || {};

// eslint-disable-next-line no-console
console.log({
  'import.meta.env.BASE_URL': import.meta.env.BASE_URL,

  'import.meta.env.MODE': import.meta.env.MODE,
  'process.env.NODE_ENV': process.env.NODE_ENV,

  'import.meta.env.BUILDER3_EMBED': import.meta.env.BUILDER3_EMBED,
  'process.env.BUILDER3_EMBED': process.env.BUILDER3_EMBED,
});

const loadAndExtendConfig = async () => {
  const fileToImport = getConfigVariant();
  const globConfigVariant = import.meta.glob('../config/**/*.ts');

  const configSiteFile = (await globConfigVariant[`../config/${fileToImport.dir}/${fileToImport.value}.ts`]()) as {
    configSite: ConfigSiteLoaded;
  };

  Object.assign(configSiteLoaded, configSiteFile.configSite);

  // TODO ELO
  // eslint-disable-next-line no-console
  console.log({ configSiteFile });
};

(async () => {
  if (process.env.NODE_ENV === 'development') {
    await import('./devInitScript');
  }

  if (!window.ngb.SITE_ID) {
    throw new Error('window.ngb.SITE_ID is not set!!!');
  }

  if (!siteIdInterviewcheckEmbedValues.includes(window.ngb.SITE_ID)) {
    throw new Error(
      `window.ngb.SITE_ID ("${window.ngb.SITE_ID}") not found in siteIdInterviewcheckEmbedValues. Possible values: ${siteIdInterviewcheckEmbedValues.join(', ')}`,
    );
  }

  if (process.env.NODE_ENV === 'development') {
    const { defaultInitParams } = await import('./defaultInitParams');

    configPassed.callbacks = defaultInitParams.callbacks;
    configPassed.footer = defaultInitParams.footer;
    configPassed.logo = defaultInitParams.logo;
    configPassed.rest = defaultInitParams.rest;
    configPassed.restAuth = defaultInitParams.restAuth;
    configPassed.restEcom = defaultInitParams.restEcom;
    configPassed.urlExternal = defaultInitParams.urlExternal;
    configPassed.urlInternal = defaultInitParams.urlInternal;
    configPassed.rendererProps = defaultInitParams.rendererProps;
    configPassed.staticRoot = defaultInitParams.staticRoot;
    configPassed.routingPathnamePrefix = defaultInitParams.routingPathnamePrefix;

    // eslint-disable-next-line no-console
    console.log({ configPassed, 'import.meta.env.BASE_URL': import.meta.env.BASE_URL });

    await loadAndExtendConfig();

    const { entryInit } = await import('./entryInit');

    await entryInit({
      additionalMessageData: defaultInitParams.i18n,
      appSelector: defaultInitParams.appSelector,
      initLoaderSelector: defaultInitParams.initLoaderSelector,
    });

    return;
  }

  // eslint-disable-next-line no-underscore-dangle
  window.__ngb_interviewcheck_embed_v1__ = window.__ngb_interviewcheck_embed_v1__ || {};

  // used in entryInitProd.js (injected to index.html in prod build)
  // eslint-disable-next-line no-underscore-dangle
  window.__ngb_interviewcheck_embed_v1__.init = async (initParams) => {
    // eslint-disable-next-line no-console
    console.log('app init…', initParams);

    if (!initParams.graphql && !(initParams.rest && initParams.restAuth && initParams.restEcom)) {
      throw new Error(
        'Required url params are not passed: single `graphql` url or 3 rest ones (`rest`, `restAuth` and `restEcom`)',
      );
    }

    configPassed.callbacks = initParams.callbacks;
    configPassed.footer = initParams.footer;
    configPassed.logo = initParams.logo;
    configPassed.graphql = initParams.graphql;
    configPassed.rest = initParams.rest;
    configPassed.restAuth = initParams.restAuth;
    configPassed.restEcom = initParams.restEcom;
    configPassed.urlExternal = initParams.urlExternal;
    configPassed.urlInternal = initParams.urlInternal;
    configPassed.rendererProps = initParams.rendererProps;
    configPassed.staticRoot = initParams.staticRoot;
    configPassed.routingPathnamePrefix = initParams.routingPathnamePrefix;

    import.meta.env.BASE_URL = initParams.staticRoot;

    // eslint-disable-next-line no-console
    console.log({ configPassed, 'import.meta.env.BASE_URL': import.meta.env.BASE_URL });

    await loadAndExtendConfig();

    const { entryInit } = await import('./entryInit');

    await entryInit({
      additionalMessageData: initParams.i18n,
      appSelector: initParams.appSelector,
      initLoaderSelector: initParams.initLoaderSelector,
    });
  };
})();
