// NEW_SITE_ID_HERE

export const SITE_ID_NGB = {
  interviewme: 'interviewme',
  resumelab: 'resumelab',
  zetybr: 'zetybr',
  zetyde: 'zetyde',
  zetydk: 'zetydk',
  zetyes: 'zetyes',
  zetyfr: 'zetyfr',
  zetyit: 'zetyit',
  zetyse: 'zetyse',
} as const;

// FTE = feedbacktool-embed
export const SITE_ID_FTE = {
  // TODO - change to myperfectresumefr for clarity?
  moncvparfait: 'moncvparfait',
  // myperfectresume.com
  myperfectresume: 'myperfectresume',
  // meucurriculoperfeito.com.br
  myperfectresumebr: 'myperfectresumebr',
  // meinperfekterlebenslauf.de
  myperfectresumede: 'myperfectresumede',
  // micvideal.es
  myperfectresumees: 'myperfectresumees',
  // ilcvperfetto.it
  myperfectresumeit: 'myperfectresumeit',
  // micvideal.mx
  myperfectresumemx: 'myperfectresumemx',
  // myperfectcv.co.uk
  myperfectresumeuk: 'myperfectresumeuk',

  // livecareer.de
  livecareerde: 'livecareerde',
  // livecareer.es
  livecareeres: 'livecareeres',
  // livecareer.fr
  livecareerfr: 'livecareerfr',
  // livecareer.it
  livecareerit: 'livecareerit',
  // livecareer.pl
  livecareerpl: 'livecareerpl',
  // livecareer.co.uk
  livecareeruk: 'livecareeruk',
} as const;

// ICE = interviewcheck-ai-embed
export const SITE_ID_ICE = {
  ...SITE_ID_NGB,
  // livecareer.co.uk
  livecareeruk: 'livecareeruk',
  // myperfectresume.com
  myperfectresume: 'myperfectresume',
} as const;

export const SITE_ID = {
  ...SITE_ID_NGB,
  ...SITE_ID_ICE,
  ...SITE_ID_FTE,
} as const;

export const siteIdFeedbacktoolEmbedValues = Object.values(SITE_ID_FTE);
export const siteIdInterviewcheckEmbedValues = Object.values(SITE_ID_ICE);

// TODO ELO
// ? SITE_ID_DEFAULT_NGB
// ? SITE_ID_DEFAULT_FTE
export const SITE_ID_DEFAULT = SITE_ID.resumelab;

// export type SiteIdKeys = keyof typeof SITE_ID;
export type SiteIdValues = ValueOf<typeof SITE_ID>;
export type SiteIdValuesNGB = ValueOf<typeof SITE_ID_NGB>;
export type SiteIdValuesFTE = ValueOf<typeof SITE_ID_FTE>;

export type SiteIdData<T = SomeObj> = Record<SiteIdValues, T>;
export type SiteIdDataNGB<T = SomeObj> = Record<SiteIdValuesNGB, T>;
export type SiteIdDataFTE<T = SomeObj> = Record<SiteIdValuesFTE, T>;
