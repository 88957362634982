import { DOCUMENT_TYPES } from 'COMMON/constants/document';

import type { PortalCDValues } from 'COMMON/constants/portalCD';
import type { PortalIDValues } from 'COMMON/constants/portalID';
import type { ConfigSiteLoaded, ConfigSiteTokens } from './types';

// moved from configSiteLoaded.ts, as configSiteLoaded.ts is replaced in StoryBook build
// TODO ELO better values
export const configSiteLoadedDefault = {
  analytics: {
    gtmIds: { local: 'to-be-replaced', production: 'to-be-replaced', testing: 'to-be-replaced' },
    segmentIds: { local: 'to-be-replaced', production: 'to-be-replaced', testing: 'to-be-replaced' },
    segmentUrls: { local: 'to-be-replaced', production: 'to-be-replaced', testing: 'to-be-replaced' },
    visitorsTrackValue: {
      includeGeolocation: true,
      includeUseragent: true,
      isRewriteConfigured: true,
      portalCd: 'to-be-replaced',
      productCd: 'to-be-replaced',
    },
  },
  language: {
    feedbackAvailable: [],
    interfaceAvailable: [],
    interviewAvailable: null,
    tipsAvailable: null,
    ttcLetrAllowed: null,
    ttcRsmeAllowed: null,
  },
  logo: {
    mobile: {
      markup: 'to-be-replaced',
      viewBox: 'to-be-replaced',
    },
    normal: {
      markup: 'to-be-replaced',
      viewBox: 'to-be-replaced',
    },
    simple: {
      markup: 'to-be-replaced',
      viewBox: 'to-be-replaced',
    },
    url: 'to-be-replaced',
  },
  restEB: { local: 'to-be-replaced', production: 'to-be-replaced', testing: 'to-be-replaced' },
  siteIdData: {
    contactEmail: {
      default: 'to-be-replaced',
    },
    name: 'to-be-replaced',
    phoneNumber: 'to-be-replaced',
    portalCD: '' as PortalCDValues,
    portalID: 0 as PortalIDValues,
    templateID: 0,
    templateIds: {
      [DOCUMENT_TYPES.CNTM]: 0,
      [DOCUMENT_TYPES.HDRQ]: 0,
      [DOCUMENT_TYPES.JBAL]: 0,
      [DOCUMENT_TYPES.LETR]: 0,
      [DOCUMENT_TYPES.RSME]: 0,
    },
  },
  tokens: {
    aiInterview: {},
    feedback: {},
  } as ConfigSiteTokens,
  urlsExternal: {} as ConfigSiteLoaded['urlsExternal'],
  userAgreements: [],
} as ConfigSiteLoaded;
