import { SESSION_STORAGE_KEYS } from '@boldpl/common/constants/sessionStorage';
import { SITE_ID } from '@boldpl/common/constants/siteId';

// TODO ELO remove FTE stuff
export const getConfigVariant = (siteId = window.ngb.SITE_ID) => {
  const debugConfigSite = sessionStorage?.getItem(SESSION_STORAGE_KEYS.theme);

  if (debugConfigSite) {
    return { dir: 'variant', value: debugConfigSite };
  }

  // TODO ELO remove FTE stuff
  if (
    [
      SITE_ID.myperfectresume,
      SITE_ID.moncvparfait,
      SITE_ID.myperfectresumees,
      SITE_ID.myperfectresumebr,
      SITE_ID.myperfectresumede,
      SITE_ID.myperfectresumeit,
      SITE_ID.myperfectresumemx,
      SITE_ID.myperfectresumeuk,
    ].includes(siteId)
  ) {
    return { dir: 'variant', value: 'mpr' };
  }

  if (
    [
      SITE_ID.livecareerde,
      SITE_ID.livecareeres,
      SITE_ID.livecareerfr,
      SITE_ID.livecareerit,
      SITE_ID.livecareerpl,
      SITE_ID.livecareeruk,
    ].includes(siteId)
  ) {
    return { dir: 'siteId', value: 'lc' };
  }

  return { dir: 'siteId', value: siteId };
};
